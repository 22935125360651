import * as THREE from 'three';
import CameraControls from 'camera-controls';
import * as Controller from './controller';

let container = null;
let renderer = null;
let windowWidth, windowHeight;

const DEG90 = Math.PI * 0.5;
const DEG180 = Math.PI;


const views = [
    {
        left: 0,
        bottom: 0,
        width: 1,
        height: 1,
        background: new THREE.Color( 0.5, 0.5, 0.7 ),
        eye: [0,0,2800],// [ 0, 300, 1800 ],
        up: [ 0, 1, 0 ],
        fov: 50,
        frustum: 120,
        container: '.view1',
        active: true,
        main: true,
        updateCamera: function ( camera, scene, mouseX ) {

        }
    },{
        left: 0,
        bottom: 0.5,
        width: 0.5,
        height: 0.5,
        background: new THREE.Color( 0.2, 0.5, 0.7 ),
        eye: [0,0,1800],
        up: [ 0, 1, 0 ],
        fov: 50,
        frustum: 90,
        rotation: -DEG90,
        container: '.view2',
        active: false,
        updateCamera: function ( camera, scene, mouseX ) {

        }
    },{
        left: 0,
        bottom: 0,
        width: 0.5,
        height: 0.5,
        background: new THREE.Color( 0.2, 0.4, 0.7 ),
        eye: [0,0,1800],
        up: [ 0, 1, 0 ],
        fov: 50,
        frustum: 90,
        rotation: DEG90,
        container: '.view3',
        active: false,
        updateCamera: function ( camera, scene, mouseX ) {

        }
    }
];



const updateSize = () => {

    if ( windowWidth != window.innerWidth || windowHeight != window.innerHeight ) {

        windowWidth = container.clientWidth;
        windowHeight = container.clientHeight;

        renderer.setSize( windowWidth, windowHeight );

    }

}

export const toggleMultiView = () => {

    $(".multi").toggleClass('bold')

    let view1 = views[0]
    let view2 = views[1]
    let view3 = views[2]

    let container1 = document.querySelector(view1.container);
    let container2 = document.querySelector(view2.container);
    let container3 = document.querySelector(view3.container);
    
    if(!view2.active){ //attivo multi view
        view1.width = 0.5;
        view1.left = 0.5;

        container1.style.width = '50%';
        container1.style.left = '50%';
        container1.style.borderWidth = '1px';
        view1.controls.setLookAt(0, 0, 120, 0,0,0, false);

        container2.style.width = '50%';
        container3.style.width = '50%';
        
        view2.active = true;
        view3.active = true;
    }else{
        view1.width = 1;
        view1.left = 0;

        container1.style.width = '100%';
        container1.style.left = '0px';
        container1.style.borderWidth = '0px';

        container2.style.width = '0';
        container3.style.width = '0';

        view2.active = false;
        view3.active = false;
    }

}

export const init = (_container, _renderer) => {

    window.views = views;
    window.toggleMultiView = toggleMultiView;

    container = _container;
    renderer = _renderer;
    
    windowWidth = container.clientWidth
    windowHeight = container.clientHeight

    var aspect = windowWidth / windowHeight;

    for ( let ii = 0; ii < views.length; ++ ii ) {

        const view = views[ ii ];
        let frustumSize = view.frustum;
        const camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, 0.1, 10000 );

        // const camera = new THREE.PerspectiveCamera( view.fov, windowWidth / windowHeight, 0.1, 10000 );
        camera.position.fromArray( view.eye );
        camera.up.fromArray( view.up );
        window.camera = camera;
        let cc = new CameraControls( camera, document.querySelector(view.container));


        cc.enabled = true;
        
       // cc.setLookAt(0, 0, 120, 0,0,0, false) 

        if(view.main){
            window.cameraControls = cc;
            cc.enabled = true;
            Controller.init(scene, cc);
        
            cc.minDistance = 0;
            cc.maxDistance = Infinity;
            
        
            cc.minDistance = 15;
        }else{
            cc.setLookAt(0, 0, 100, 0,0,0, false) 
            if(view.rotation)
                cc.rotateTo( view.rotation, DEG90, false ); //inserire rotazioni parametrate
        }

        view.controls = cc;
        view.camera = camera;

    }
}

let color = new THREE.Color();


export const renderMulti = (delta) => {
    
    updateSize();

    let update = false;
    for ( let ii = 0; ii < views.length; ++ ii ) {
        const view = views[ ii ];
        if(view.controls.update( delta )){
            update = true;
        }
    }
    for ( let ii = 0; ii < views.length; ++ ii ) {

/*
        if(ii == 0){
            
            let o = scene.children[4].children[0];
            let geometry = o.geometry
            
            let count = geometry.attributes.position.count;
            let colors = geometry.attributes.color;


            for(let i=0;i<count;i++){
                
                let c1 = colors.getX(i);
                let c2 = colors.getY(i);
                let c3 = colors.getZ(i);
                
                color = color.setRGB(c1,c2,c3);
                if(color.g < 1){
                    //color = color.setRGB(c1,c2,c3).convertSRGBToLinear()
                    //color.r += 0.65
                    
                    color.setHSL(settings.hue,settings.saturation,settings.light);
                   // console.log("SET!")
                }
                    
                
                colors.setXYZ(i, color.r, color.g, color.b);

            } 

            geometry.attributes.color.needsUpdate = true
            o.material.needsUpdate = true;
        }
*/
        const view = views[ ii ];
        if(!view.active) return;
        
        const camera = view.camera;

        //view.updateCamera( camera, scene, mouseX, mouseY );

        const left = Math.floor( windowWidth * view.left );
        const bottom = Math.floor( windowHeight * view.bottom );
        const width = Math.floor( windowWidth * view.width );
        const height = Math.floor( windowHeight * view.height );

        renderer.setViewport( left, bottom, width, height );
        renderer.setScissor( left, bottom, width, height );
        renderer.setScissorTest( true );
        //renderer.setClearColor( view.background );

        let aspect = width / height;
      
        camera.left = view.frustum * aspect / - 2;
        camera.right = view.frustum * aspect / 2;
        camera.top = view.frustum / 2;
        camera.bottom = - view.frustum / 2;

        camera.updateProjectionMatrix();
        /*
        camera.aspect = width / height;
        camera.updateProjectionMatrix();*/

        //if(update || controlState.updateRender){
            renderer.render( scene, camera );
        //}
        

    }
}