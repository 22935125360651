import './style/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import * as THREE from 'three'
import {DebugEnvironment} from 'three/examples/jsm/environments/DebugEnvironment';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

import CameraControls from 'camera-controls';

import 'jszip-utils';
import * as Controller from './modules/controller';
import * as stepManager from './modules/step-manager';
import * as multiView from './modules/multi-view';
import * as measure from './modules/measure-tool';

import '@simonwep/pickr/dist/themes/monolith.min.css'
import '@simonwep/pickr/dist/themes/classic.min.css'; 
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import '@fortawesome/fontawesome-free/js/all';

import _ from 'lodash';
 import { detect } from 'detect-browser';

import { gsap } from "gsap";

import { GUI } from 'lil-gui';

window.$ = $;

/**
 * Sizes
 */
const sizes = {}
const canvas = document.querySelector('.canvas-container');

sizes.width = canvas.clientWidth
sizes.height = canvas.clientHeight


// Scene
let scene = new THREE.Scene()
let camera;
let cameraControls;
let mixers = [];
let actions = [];
let background = false;
window.settings = {
                    'hue': 0.9408,
                    'saturation': 0.668,
                    'light': 0.636
                }

const clock = new THREE.Clock();
CameraControls.install( { THREE: THREE } );
THREE.ColorManagement.enabled = true;

window.THREE= THREE;
window.actions = actions;
window.baseUrl = "https://setup.allineatoriinvisibili.it";
//window.baseUrl = "http://localhost";
window.multi = false;


let browser = detect();

if(browser.os == 'iOS'){
    $(".main-container").addClass('ios')
    $("html").addClass('ios')
    $("body").addClass('ios')
    $(".show-steps").addClass('ios')
}

window.addEventListener('resize', () =>
{
    resizeCanvas();
})

const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('.webgl'),
    //preserveDrawingBuffer: true,
    antialias: true, 
    alpha: true
})


 
window._renderer = renderer;


window.resizeCanvas = () => {
    // Save sizes
    let canvas = document.querySelector('.canvas-container');
    sizes.width = canvas.clientWidth
    sizes.height = canvas.clientHeight


    // Update renderer
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(sizes.width, sizes.height, true)
    
    
    if(camera != null){
        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
        renderer.render( scene, camera );
    }

    controlState.updateRender = true;
}

/**
 * Loop
 */
window.loop = () =>
{

    //if(scene.init == true){
        const delta = clock.getDelta();
        let hasControlsUpdated = false; //window.cameraControls.update( delta );
    
        if(Controller.mixers.length > 0){
            Controller.mixers.forEach((m) => {
                m.update( delta );
            });
            
            Controller.actions.forEach((a) => {
                if(a.isRunning()){
                    hasControlsUpdated = true;
                    //renderer.render( scene, camera );
                }
            });
            
        }
    
    
        //if(window.multi){
        multiView.renderMulti(delta);
        //}

        if(hasControlsUpdated || controlState.updateRender){
           // renderer.render( scene, camera );
            if(controlState)
                controlState.updateRender = false;
        }
        


       
    //}

    window.requestAnimationFrame(loop)
        

}




const getUrlParams = () =>{
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    
    
    let version = searchParams.get('version');

    $(".img_p_"+version).show();
    
    loadModelByVersion(version);


}

const startLoading = () => {

    const url = new URL(location.href);
    const params = url.searchParams;

    let param = params.get('hash'); 
    let ver = params.get('version');
    let debug = params.get('debug');
    if(debug == 'true'){
        $(".debug").removeClass('d-none');
    }
    if(param == null){
        
        $(".loading").html("Accesso negato.<br>Per accedere è necessario utilizzare il link fornito dall'azienda.")
        $(".loading").css('visibility','visible')

        return;
    }

    const loader = new THREE.ObjectLoader();
    loader.load(
        // resource URL
        "assets/scene.json",
    
        // onLoad callback
        // Here the loaded data is assumed to be an object
        async function ( obj ) {
            mixers = [];
            actions = [];
            
            //scene = new THREE.Scene();// obj;
            scene = obj;
            scene.children[3].intensity = 1.4
            //camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 10000);
            //camera = new THREE.OrthographicCamera( sizes.width / - 200, sizes.width / 200, sizes.height / 200, sizes.height / - 200, 1, 1000 );

            
            //scene.add(camera);
            /*
        	const pmremGenerator = new THREE.PMREMGenerator( renderer );
            pmremGenerator.compileCubemapShader();

            const envScene = new DebugEnvironment();
            window.generatedCubeRenderTarget = pmremGenerator.fromScene( envScene );
            */

            


            renderer.setClearColor( 0x000000, 0 );
            renderer.setPixelRatio(window.devicePixelRatio)
            renderer.setSize(sizes.width, sizes.height)
                
            renderer.outputEncoding = THREE.sRGBEncoding;
            
            renderer.shadowMap.enabled = true;
            
            renderer.shadowMap.type = THREE.PCFShadowMap;
            renderer.toneMapping = THREE.LinearToneMapping;
            renderer.toneMappingExposure = 0.7;
            renderer.physicallyCorrectLights = false;
    
            //let texture = await new RGBELoader().setPath( './assets/images/' ).loadAsync('lights.hdr')

            

            //texture.mapping = THREE.EquirectangularReflectionMapping;

            //scene.background = texture;
            //scene.environment = texture;

            

            window.scene = scene;
            
            multiView.init(document.querySelector('.canvas-container'), renderer);
    

    
            fetch(baseUrl+'/api/frontend/setup/'+param).then((response) => response.json())
            .then((data) => {
                window.setup = data;
                
                if(data.view_paziente) $(".allegati").hide();
                
                let versione = _.cloneDeep(data).versioni.pop();
    
                if(ver != null)
                    versione = _.cloneDeep(data).versioni[ver-1];
                    
                 //poi scegliere la prima versione 
                setup.versione_corrente = versione;
                Controller.initConfig();
                stepManager.init(versione);
                Controller.loadSetup(versione);

/*
                const panel = new GUI( { width: 410 } );
                const folder1 = panel.addFolder( 'HSL' );


                folder1.add( settings, 'hue', 0, 1, 0.0001 );
                folder1.add( settings, 'saturation', 0, 1, 0.001 );
                folder1.add( settings, 'light', 0, 1, 0.001 );
*/
            });
    
        },
    
        // onProgress callback
        function ( xhr ) {
            console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
        },
    
        // onError callback
        function ( err ) {
            console.error( 'An error happened' );
        }
    );
}

startLoading();

window.loadVersion = (id) => {
    scene.getObjectByName('superiore').removeFromParent();
    scene.getObjectByName('inferiore').removeFromParent();
    let versione = setup.versioni.find((el) => el.id == id);
    setup.versione_corrente = versione;
    Controller.resetLoadBar();
    Controller.initConfig();
    stepManager.init(versione, false);
    Controller.loadSetup(versione);
}



function initAnimation(obj){
    let mixer = new THREE.AnimationMixer(obj);

    let action = mixer.clipAction(obj.animations[0]);
    action.setLoop( THREE.LoopOnce );
    action.clampWhenFinished = true;

    mixers.push(mixer);
    actions.push(action);
}

window.esporta = () =>{
    var output = scene.toJSON();
    output = JSON.stringify( output, 1, '\t' );
    output = output.replace( /[\n\t]+([\d\.e\-\[\]]+)/g, '$1' );
    saveString(output, "scena.json");
}   


function save( blob, filename ) {

    var link = document.createElement( 'a' );
    link.style.display = 'none';
    document.body.appendChild( link );

    link.href = URL.createObjectURL( blob );
    link.download = filename;
    link.click();

}

function saveString( text, filename ) {

    save( new Blob( [ text ], { type: 'text/plain' } ), filename );

}
