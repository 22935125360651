import * as THREE from 'three';
import CameraControls from 'camera-controls';
import * as Controller from './controller';

window.measureActive = false;
let scene = null;
let camera = null;
let container = null;
var points, clicks, markerA, markerB, markers, lineGeometry, line;

    
var lineMaterial = new THREE.LineBasicMaterial({
    color: 0xff5555
});

export const init = (_container) => {
    scene = window.scene;
    camera = window.camera;
    container = _container;
    
    container.addEventListener("mousedown", onDocumentMouseDown, false);

    points = [
        new THREE.Vector3(),
        new THREE.Vector3()
    ]
    clicks = 0;
    
    markerA = new THREE.Mesh(
        new THREE.SphereGeometry(2, 10, 20),
        new THREE.MeshBasicMaterial({
            color: 0xff5555
        })
    );
    
    markerB = markerA.clone();
    markers = [
        markerA, markerB
    ];
    

 
    scene.add(markerA);
    scene.add(markerB);
    

}





const onDocumentMouseDown = (event) => {

    if (!window.measureActive) return;

    var intersects = getIntersections(event);

    if (intersects.length > 0) {

        points[clicks].copy(intersects[0].point);
        markers[clicks].position.copy(intersects[0].point);
        setLine(intersects[0].point, intersects[0].point);
        clicks++;
        if (clicks > 1) {
            var distance = points[0].distanceTo(points[1]);
            $(".measure").text(distance);
            setLine(points[0], points[1]);
            clicks = 0;
        }
    }
}

const getIntersections = (event) => {
    var vector = new THREE.Vector2();

    console.log(event)
    vector.set(
        event.clientX / window.innerWidth * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
    );

    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(vector, camera);

    var intersects = raycaster.intersectObjects(scene.children, true);

    return intersects;
}

function setLine(vectorA, vectorB) {

    if(scene.getObjectByName('ruler'))
        scene.getObjectByName('ruler').removeFromParent(); 

    
    lineGeometry = new THREE.BufferGeometry().setFromPoints( [vectorA,vectorB] );
    line = new THREE.Line(lineGeometry, lineMaterial);
    line.name = 'ruler';
    scene.add(line);
}
