// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/HelveticaNeueLTStd-BdCn.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/HelveticaNeueLTStd-BdCn.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/HelveticaNeueLTStd-LtCnO.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/HelveticaNeueLTStd-LtCnO.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/HelveticaNeueLTStd-LtCn.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/HelveticaNeueLTStd-LtCn.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../assets/fonts/HelveticaNeueLTStd-BdCnO.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../assets/fonts/HelveticaNeueLTStd-BdCnO.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Helvetica Neue LT Std';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Helvetica Neue LT Std';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n    font-weight: 300;\n    font-style: italic;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Helvetica Neue LT Std';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n    font-weight: 300;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Helvetica Neue LT Std';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff');\n    font-weight: bold;\n    font-style: italic;\n    font-display: swap;\n}\n\n", ""]);
// Exports
module.exports = exports;
