import Pickr from '@simonwep/pickr';
import * as THREE from 'three';
import 'bootstrap';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import moment from 'moment';
import 'magnific-popup';


// Loaded via <script> tag, create shortcut to access PDF.js exports.

let scene;
let config = {};
let zoomButtons = [];
let cameraControls;
let currentObject;

export let actions = [];
export let mixers = [];

window.controlState = {
    updateRender: false
};

const DEG90 = Math.PI * 0.5;
const DEG180 = Math.PI;

export const loadModel = async (name) => {


    let loadBar1 = document.querySelector(".loader .indicator");

    return new Promise(async (resolve, reject )=>{
        /*
        let resp = await fetch(baseUrl+'/api/frontend/asset-link/'+name+'.zip');
        const link = await resp.json();
        */
       
        JSZipUtils.getBinaryContent(baseUrl+'/api/frontend/asset/'+name+'.zip', { 

            progress: (update) =>  {
                //console.log(e.percent + "% loaded");
                loadBar1.style.width = update.percent + '%';
                //loadBar1.style.width = loadBar.style.width;
            },
            callback: (err, data) => {
                if(err) {
                    throw err; // or handle err
                }

                resolve(data);
            }});
    });

}

export const resetLoadBar = () => {
    //let loadBar = document.querySelector(".main-load-bar .indicator");
    let loadBar1 = document.querySelector(".loader .indicator");
    loadBar1.style.width = 0;
};


export const generaPopoverMenu = () => {
    let html = "";
    currentObject.versioni.forEach((el, i) => {
        if(el.type && el.type == 'separator'){
            html += "<hr>";
        }else{
            html += '<div class="btn-opt-'+i+'">'+el.menu+'</div>';
        }
        
    });

    $(".popover-btn").html(html);
};




export const initConfig = (id = 1) => {
    setupButtons();
    $(".loader").show()
    $(".loading").css('visibility','visible')
    $(".main-load-bar").show();
}



export const loadSetup = async (data) => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/libs/draco/gltf/' );
    dracoLoader.preload();

    loader.setDRACOLoader( dracoLoader );
    let loadBar = document.querySelector(".loader .indicator");
    
    let totElems = 0;
    let step = data.dettagli;

    for(let key in step){
        totElems += step[key].length
    }

    let barStep = 100 / totElems;
    let barWidth = 0;

    
    let file = await loadModel(data.file);

    let zip = await JSZip.loadAsync(file); 

    let color = new THREE.Color();

    for(let key in step){
        let group = new THREE.Group();
        group.name = key;

        for(let layer of step[key]){
            let filename = data.file+'_'+layer.name+'.glb';
            let sceneFile = zip.file(filename);

            let s = await sceneFile.async('ArrayBuffer');
            
            let gltf = await loader.parseAsync(s);

            s =  null;

            let obj = gltf.scene.children[0];
            let geometry = obj.geometry
            
            let count = geometry.attributes.position.count;
            let colors = geometry.attributes.color;

            let index_list = [];

            for(let i=0;i<count;i++){
                
                let c1 = colors.getX(i);
                let c2 = colors.getY(i);
                let c3 = colors.getZ(i);
                
               // console.log(c1,c2,c3)
                color = color.setRGB(c1,c2,c3)//.convertSRGBToLinear();
                if(color.r < 0.8 && color.g < 0.8){

                    index_list.push(i);
                }

            } 
            
            obj.userData.index_list = index_list;

            /*
            for(let i=0;i<count;i++){
                
                let c1 = colors.getX(i);
                let c2 = colors.getY(i);
                let c3 = colors.getZ(i);
                
                color = color.setRGB(c1,c2,c3)//.convertSRGBToLinear();
                if(color.r < 0.7 && color.g < 0.7){
                    //color.r += 0.65
                    color.setHSL(0.9408,0.668,0.636);
                }

                
                colors.setXYZ(i, color.r, color.g, color.b);

            } 
            
            geometry.attributes.color.needsUpdate = true
            */
            obj.position_backup = _.cloneDeep(obj.position);
            obj.visible = false;
            obj.material.roughness = 0.50;
            obj.material.metalness = 0.00; 

            //v1
            /*
            obj.material.roughness = 0.5;
            obj.material.metalness = 0.09;     
            */

            //v2
            /*
            obj.material.roughness = 0.40;
            obj.material.metalness = 0.08; 
            */

            //v3
            /*
            obj.material.roughness = 0.40;
            obj.material.metalness = 0.19;
            */

            $("#roughness").val(obj.material.roughness);
            $("#metalness").val(obj.material.metalness);
            
            if(layer.step == 1)
                obj.visible = true;

            group.add(obj);

            barWidth += barStep;
            
            loadBar.style.width = barWidth + '%';
            
        }

        scene.add(group);
    }

    zip = null;

    controlState.updateRender = true
    //cercare il centro con il boundigbox portando indietro la z
    cameraControls.setLookAt(0, 0, 150, 0,0, -6, false);
    updateSetupDetails();
    generaFotoPaziente();
    initStepTracker();
    generaTabellaSetup();
    showStep('superiore', 1);
    showStep('inferiore', 1);
    changeColor('#da585e');
    setTimeout(()=>{
        $(".loader").fadeOut();
    }, 1800);
    
    controlState.updateRender = true
    window.loop();

    
    return;


}

const generaTabellaSetup = () => {
    
    if(setup.versione_corrente.xls != null){
        for(let a in setup.versione_corrente.xls){
            let table = setup.versione_corrente.xls[a];
            let html = '<thead><tr>';
            for(let k in table[0]){
                html += '<th>'+k+'</td>';
            }
            html += '</tr></thead>';
            table.forEach((el, i) => {
                
                html += '<tr>';
                for(let key in el){
                    html += '<td>'+el[key]+'</td>';
                }
                html += '</tr>';
            });
        
            $(".table-"+a).html(html);

        }
    }
}


const updateSetupDetails = () =>{

    const url = new URL(location.href);
    const params = url.searchParams;

    $(".paziente").text(setup.cliente.nome+' '+setup.cliente.cognome);
    $(".clinico").text(setup.clinico.denominazione);

    let html = '';
    let allegati = setup.allegati.filter((el) => {return el.visibilita == 'generale' || el.visibilita == 'versione'});

    allegati.forEach((el) => {

        //html += '<tr><td>'+el.titolo+'</td><td class="text-right"><a target="_blank" href="'+baseUrl+'/api/allegato/'+el.id+'">Visualizza</a></td></tr>'
        html += '<tr><td>'+el.titolo+'</td><td class="text-right"><a onclick="showPDF(\''+baseUrl+'/api/allegato/'+el.id+'\')" href="#">Visualizza</a></td></tr>'
    });

    $("table.allegati tbody").html(html);

    html = '';
    let versioni = setup.versioni;
    versioni.forEach((el, i) => {

        el.data_formatted = moment(el.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')
        let style = '';
        if(el.id == setup.versione_corrente.id) style = 'font-weight: bold';
        el.titolo = el.titolo != null ? el.titolo : 'Trattamento '+(i+1);
        html += '<tr><td>'+el.data_formatted+'</td><td><a style="'+style+'" href="'+url.origin+'/?hash='+params.get('hash')+'&version='+(i+1)+'">'+el.titolo+'</a></td></tr>'
    });

    $("table.versioni tbody").html(html);


    /* pannello sinistra */
    let ver = setup.versione_corrente;
    ver.data = ver.data == null ? ver.created_at : ver.data;
    ver.data_formatted = moment(ver.data, 'YYYY-MM-DD').format('DD-MM-YYYY');
    $(".n-versione").text(ver.titolo);
    $(".data-versione").text(ver.data_formatted);
    $(".step-superiore").text(ver.dettagli.superiore.length+" STEP");
    $(".step-inferiore").text(ver.dettagli.inferiore.length+" STEP");


    let superiore = ver.dettagli.superiore;
    let inferiore = ver.dettagli.inferiore;
    
    let pos = 'inferiore'
    let other = 'superiore';
    if(superiore.length > inferiore.length){
        pos = 'superiore';
        other = 'inferiore';
    }

    let arr = _.cloneDeep(ver.dettagli[pos]);
    arr.forEach((el) => {
        el.pos = pos;
        let a1 = ver.dettagli[other].find((o) => o.step == el.step);
        if(a1){
            a1.pos = other;
            el.other = a1;
        }
    });

    html = '';
    arr.forEach((el) => {
        
        if(el.descrizione != null || el.allegati.length > 0 || 
            (el.other != null && (el.other.descrizione != null || el.other.allegati.length > 0))){

            html += '<div class="step step-'+el.step+'" id="'+el.uid+'">';
            html += '<div class="titolo"><a href="#" onclick="selectStep('+el.step+');"><b>STEP '+el.step+'</b></a></div>';
    
            if(el.descrizione != null || el.allegati.length > 0){
                html += '<div class="arcata">Arcata '+el.pos+'</div>';
                if(el.descrizione != null){
                    html += '<p>'+el.descrizione+'</p>'
                }
    
                html += generaAllegati(el)

                html += generaGallery(el);
                
                if(el.other != null && (el.other.descrizione != null || el.other.allegati.length > 0))
                    html += '<hr/>';
            }
    
            if(el.other != null && (el.other.descrizione != null || el.other.allegati.length > 0)){
                
                html += '<div class="arcata">Arcata '+el.other.pos+'</div>';
                if(el.other.descrizione != null){
                    html += '<p>'+el.other.descrizione+'</p>'
                }
    
                html += generaAllegati(el.other)
                html += generaGallery(el.other);

            }
    
            html += '</div>'           
        
        }

    });


    $(".step-info").html(html);

    $(".img-zoom").magnificPopup({type: 'image'});

}

const generaFotoPaziente = () => {
    
    if(setup.foto == null) return;
    
    let html = '';
    setup.foto.forEach((el) => {
        let img = '<img onclick="rotateTo(\''+el.pos+'\')" loading="eager" src="'+baseUrl+'/api/picture/immagini/'+el.file+'" class="img-thumbnail foto"/>'
        html += img;
    });

    $(".foto-paziente").html(html)
    $(".foto-paziente").hide();
}

const generaAllegati = (el) => {

    let html = '';
    if(el.allegati.length > 0){
    
        html += "<div>Allegati<br><ul class='allegati'>";
        el.allegati.forEach((a) => {
            if(a.tipologia == 'Documento')
                html += '<li><a href="'+baseUrl+'/api/allegato/'+a.id+'">'+(a.titolo != null ? a.titolo : a.file)+'</a></li>';
        })

        html += '</ul></div>';
    }

    return html;
}



const generaGallery = (el) => {
    let html = '';
    let filtered = el.allegati.filter(el => el.tipologia == 'Immagine');

    if(filtered.length > 0){
    
        html += "<div class='row'>";
        filtered.forEach((a) => {
            
            html += '<div class="col-sm-4"><a class="img-zoom" href="'+baseUrl+'/api/allegato/'+a.id+'"><img loading="eager" class="img-fluid" src="'+baseUrl+'/api/allegato/'+a.id+'" /></a></div>';
          //  $(".preload-img").append('<link rel="preload" as="image" href="'+baseUrl+'/api/allegato/'+a.id+'">')
        })

        html += '</div>';
    }

    return html;
}

const changeModel = (id) => {

    resetLoadBar();
    $('.btn').removeClass('selected');
    $('.loader .load-bar').show()

    $(".loader").fadeIn({
        duration: 500,
        complete: () => {
            loadModel(id, '/', async (obj) => {
                
                //scene = scene.clone(true);
                const arcate = ['superiore', 'inferiore'];

                arcate.forEach((a) => {
                    let group = obj.scene.getObjectByName(a);
                    group.children.forEach((el, i) => {

                        el.material.roughness = 0.46;
                        el.material.metalness = 0.24;

                        if(el.name != a+'_1')
                         el.visible = false;
                    })
                })

                scene.add(obj.scene)
                scene.init = true;
                controlState.updateRender = true

                cameraControls.setLookAt(0, 0, 150, 0,0,0, false);

                setTimeout(() => {
                    $(".loader").hide();
                    controlState.updateRender = true
                    window.loop();
                    
                }, 500)

            });

            

        }
    })


}

export const init = (_scene, _cameraControls) => {

    scene = _scene;
    cameraControls = _cameraControls;


    setTimeout(()=>{
        //$(".loader").fadeOut();
        //$(".loader").fadeOut()
    })
    

}

export const setupPopover = () => {


    let html = $('#popover-content').html();
    $('#popover-content').empty();
    $('#popover-content').html('<div class="popover-btn"></div>')
    $("[data-toggle=popover]").popover({
        html: true,

        content: function() {
            return html
        }
    });


}

window.showPDF = (url) => {
    /*
    console.log(pdfjsLib)
    var loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then(function(pdf) {
        console.log(pdf)
    });*/

    // type="application/pdf"
    
    let html = '<object id="pdf-container" data="'+url+'"  width="100%" height="900px"><p>Unable to display PDF file.</p></object>'
    $("#pdf-modal .modal-body").html(html);
    $("#pdf-modal").modal('show');
    
}

const toggleMobileMenu = () => {
    $(".navbar-collapse").removeClass('show');
}



window.rotateTo = (side) => {

    toggleMobileMenu();

    cameraControls.setLookAt(0, 25, 120, 0,0,0, true)

    $('.pos-btn').removeClass('active');

	switch ( side ) {
        
		case 'front':
			cameraControls.rotateTo( 0, DEG90, true );
			break;

		case 'back':
			cameraControls.rotateTo( DEG180, DEG90, true );

			break;

		case 'up':
			cameraControls.rotateTo( 0, 0, true );

			break;

		case 'down':
			cameraControls.rotateTo( 0, DEG180, true );

			break;

		case 'right':
			cameraControls.rotateTo( DEG90, DEG90, true );

			break;

		case 'left':
			cameraControls.rotateTo( - DEG90, DEG90, true );
			//gsap.to( gridHelper.position, { duration: 0.8, x: - 1, y: 0, z: 0 } );
			//gsap.to( gridHelper.rotation, { duration: 0.8, x: - DEG90, y: 0, z: DEG90 } );
			break;

	}

    $('.move-'+side).addClass('active');

}

export const initAnimations = (obj_name) => {


    if(actions.length > 0){
        end = false;
        actions.forEach((a) => {
            a.stop();
            a.reset();
        });
    }


    actions = [];
    mixers = [];

    let p = scene.getObjectByName(obj_name).getObjectByName('anta')

    initAnimation(p);

    let c = scene.getObjectByName(obj_name).getObjectByName('cerniere')
    if(c){
        initAnimation(c);
        actions[0].syncWith(actions[1]);
    }
        

    
}

export const initAnimation = (obj) => {
    let mixer = new THREE.AnimationMixer(obj);

    let action = mixer.clipAction(obj.animations[0]);
    action.setLoop( THREE.LoopOnce );
    action.clampWhenFinished = true;

    mixers.push(mixer);
    actions.push(action);
}


let end = false;
const anima = () => {
    
    if(!end){
        actions.forEach((a) => {
            a.reset();
            a.time = 0;
            a.timeScale = 1;
            a.play();
        })


        end = true;
    }else{

        actions.forEach((a) => {
            a.timeScale = -1;
            a.paused = false;
        });

        end = false;
    }

}


export const sendEmail = () => {
    let data = {};

    let txt = $(".btn-send").text();


    data.nome = $('#nome').val();
    data.cognome = $('#cognome').val();
    data.email = $('#email').val();

    
    $(".btn-send").attr('disabled', 'disabled');
    $(".btn-send").text("Invio in corso...");

    setTimeout(()=>{
        $(".btn-send").text("Richiesta Inviata");

        setTimeout(()=>{
            $(".btn-send").text(txt);
            $(".btn-send").removeAttr('disabled');
        },2000)
    },3000 );
}

export const setupButtons = () => {
    $('.pos-btn').each((i, el) => {
        $(el).on('click', () => {
            let side = $(el).data('side');
            rotateTo(side);
        })
    });

    $('.arc-btn').each((i, el) => {

        $(el).on('click', () => {
            $('.arc-btn').removeClass('active');
            $(el).addClass('active');
            let side = $(el).data('side');
            toggleArc(side);
        })
    });

    $('.over-btn').on('click', () => {
        abilitaSovrapposizione();
    });
 
    $('.jump-btn').on('click', () => {
        toggleJump();
    });


}

window.matValues = () => {
  
    let roughness = parseFloat($("#roughness").val());
    let metalness = parseFloat($("#metalness").val());
    
    if(isNaN(roughness)) roughness = 0;
    if(isNaN(metalness)) metalness = 0;

    scene.getObjectByName('superiore').traverse((obj) => {
        if(obj.isMesh){

            obj.material.roughness = roughness;
            obj.material.metalness = metalness; 
            obj.material.needsUpdate = true;
            
        }
    });

};

window.changeColor = (color, roughness = 0, metalness = 0) => {

    //console.log(color)
    let new_color = new THREE.Color(color);

    scene.getObjectByName('superiore').traverse((obj) => {
                    
        applyColorToObject(obj, new_color, roughness, metalness);
    });

    scene.getObjectByName('inferiore').traverse((obj) => {

        applyColorToObject(obj, new_color, roughness, metalness);

    });

}

const applyColorToObject = (obj, new_color, roughness, metalness) => {

    if(obj.isMesh){
        obj.material.roughness = roughness;
        obj.material.metalness = metalness; 
    }



    if(obj.userData.index_list != null){
        
        let geometry = obj.geometry
        
        let colors = geometry.attributes.color;

        obj.userData.index_list.forEach((i) => {
            colors.setXYZ(i, new_color.r, new_color.g, new_color.b);
        });
        
        
        geometry.attributes.color.needsUpdate = true        
    }
}

export const resetZoom = async () => {
    $('.zoom-btn-clone').removeClass('d-none');
    cameraControls.setLookAt(0, 45, 238, 0, 45,0, true)

}