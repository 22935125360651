import * as THREE from 'three'
import { gsap } from "gsap";

//cameraControls globale
let time = 200;
let timers = {};
let positions = {};
let scene = null;
let stepTracker = {};
let isPlaying = false;
let containerPadding = 0;
let currentStep = 1;
let maxStep = 1;
let sovrapposizione = false;
let currentPanel = '';

window.jump = false;

export const init = (data, reset = true) => {
    //console.log("init", data)
    //console.log(cameraControls)
    scene = new THREE.Scene();
    buildPlayer(data.dettagli)

    if(reset){
        window.play = play;
        window.playAll = playAll;
        window.playSync = playSync;
        window.showStep = showStep;
        window.toggleArc = arcate;
        
        window.stepNext = stepNext;
        window.stepBack = stepBack;
        window.selectStep = selectStep;
        window.abilitaSovrapposizione = abilitaSovrapposizione;
        window.currentStep = currentStep;
        
        window.initStepTracker = initStepTracker;
        
        window.toggleRightPanel = toggleRightPanel;
        window.toggleLeftPanel = toggleLeftPanel;
        
        window.hideRightPanel = hideRightPanel;
        window.showRightPanel = showRightPanel;
        
        window.toggleGrid = toggleGrid;
        window.toggleJump = toggleJump;
        
        window.rightPanelOpen = false;
        window.leftPanelOpen = false;
    }

}

const buildPlayer = (posizioni) => {
    $(".number-cnt").empty();
    $(".step-container").empty();
    scene = window.scene;
    //posizioni.inferiore.splice(6, 2); //test
    positions = posizioni;
    for(let key in posizioni){
        //$(".step-container").append('<div class="step-row '+key+'"><button onclick="play(\''+key+'\')" class="btn play-row '+key+'"><i class="fa fa-play"></i></button></div>')
        $(".number-cnt").append('<div class="step-row"><button class="btn current-step-'+key+'">1</button></div>')
        $(".step-container").append('<div class="step-row '+key+'"></div>')

        for(let el of posizioni[key]){
            $(".step-row."+key).append('<button onclick="showStep(\''+key+'\', '+el.step+')" class="btn step-btn '+el.name+'"><i class="fa-regular fa-circle"></i></button>')
        }

        if(key == 'superiore') $(".step-container").append('<hr/>')
    }
}

export const playAll = () => {

    if(isPlaying){
        
        isPlaying = false;
        stop();
        for(let t in timers){
            clearInterval(timers[t])
        }

    }else{

        $(".play-all").html('<i class="fa fa-pause fa-2x"></i>');
        isPlaying = true;
        for(let position in positions){
            play(position);
        }
    }
}


export const stop = () => {
    $(".play-all").html('<i class="fa fa-play fa-2x"></i>');
    $(".play-row").html('<i class="fa fa-play"></i>');
}

export const playSync = () => {
    let s_len = positions.superiore.length;
    let i_len = positions.inferiore.length;
    stepTracker.superiore = {time: time};
    stepTracker.inferiore = {time: time};
    
    if(s_len > i_len){
        stepTracker.inferiore.time = (time*s_len) / i_len 
        stepTracker.superiore.isMaster = true;
    }else{
        stepTracker.superiore.time = (time*i_len) / s_len 
        stepTracker.inferiore.isMaster = true;
    }
    
    
    playAll();
}

export const playStandard = () => {
    stepTracker.inferiore.time = time;
    stepTracker.superiore.time = time;
    playAll();      
}

export const play = (position) => {

    initStepTracker();

    stepTracker[position].step = 1;
    currentStep = 1;

    let step = stepTracker[position].step;
    timers[position] = setInterval(()=>{

        showStep(position, step);
        if(step < stepTracker[position].max){
            step++;
            stepTracker[position].step = step;
        }else{
            
            clearInterval(timers[position]);
        }

        if(stepTracker[position].isMaster){
            if(currentStep < maxStep) currentStep++;

            if(currentStep >= maxStep){
                isPlaying = false;
                stop();
            }
        }
 

    }, stepTracker[position].time);
    
    
}

const toggleJump = () => {
    jump = !jump;

    $(".jump").text(jump ? 'Disattiva Jump' : 'Attiva Jump')
    $(".jump").toggleClass('bold')
    $(".jump-btn").toggleClass('active')
    
    
    toggleMobileMenu();

}

const initStepTracker = () => {

    maxStep = 0;
    for(let position in positions){
        if(stepTracker[position] == null) stepTracker[position] = {};

        if(stepTracker[position].time == null) stepTracker[position].time = time;
        if(stepTracker[position].step == null) stepTracker[position].step = 1;
        stepTracker[position].max = positions[position].length; //prendo il lenght intero perché si parte da 1
        if(maxStep < stepTracker[position].max) maxStep = stepTracker[position].max;
        
    }

}

const selectStep = (step_number) => {

    stepTracker.superiore.step = step_number;
    stepTracker.inferiore.step = step_number;
    
    if(step_number > stepTracker.superiore.max) stepTracker.superiore.step = stepTracker.superiore.max;
    if(step_number > stepTracker.inferiore.max) stepTracker.inferiore.step = stepTracker.inferiore.max;

    currentStep = step_number;

    showStep('superiore', stepTracker.superiore.step);
    showStep('inferiore', stepTracker.inferiore.step);
}

const stepNext = () => {
    initStepTracker();

    if(currentStep < maxStep) currentStep++; 
    let step = selectedStep;

    for(let position in positions){
        //stepTracker[position].step = stepTracker[position].max;
        if(step < stepTracker[position].max){
            stepTracker[position].step = step;
        }

        if(stepTracker[position].step < stepTracker[position].max){
            stepTracker[position].step++;
            showStep(position, stepTracker[position].step);
        }
    }

}

const stepBack = () => {
    initStepTracker();

    currentStep = selectedStep
    if(currentStep > 1) currentStep--;


    for(let position in positions){
        if(stepTracker[position].step > 1){
     

            if(stepTracker[position].max >= currentStep){
                stepTracker[position].step = currentStep;
                showStep(position, currentStep);
            }
                
        }
    }

}

let selectedStep = 1;

const showStep = (position, step) => {
    let elem = scene.getObjectByName(position+'_'+step);

    if(elem){
        hideAll(position);
        selectedStep = step;
        stepTracker[position].step = step;

        if(jump){

            let data = setup.versione_corrente.dettagli[position].find((el => el.step == step));

            if(data.jump){
                if(data.jump.z != null) elem.translateZ(parseFloat(data.jump.z))
                if(data.jump.y != null) elem.translateY(parseFloat(data.jump.y))
                if(data.jump.x != null) elem.translateX(parseFloat(data.jump.x))

                if(sovrapposizione){
                    let el = scene.getObjectByName(position+'_1');
                    if(data.jump.z != null) el.translateZ(parseFloat(data.jump.z))
                    if(data.jump.y != null) el.translateY(parseFloat(data.jump.y))
                    if(data.jump.x != null) el.translateX(parseFloat(data.jump.x))
                }
            }

            //elem.translate(0, step.jump.y, step.jump.z)
        }
            
        elem.visible = true;
        elem.material.vertexColors = true;
        elem.material.color = new THREE.Color('#FFFFFF');
        elem.material.transparent = false;
        elem.material.needsUpdate = true;

        if(sovrapposizione && step > 1){

            showOverlay(position);
        }

        controlState.updateRender = true;
        $(".current-step-"+position).text(step)
        $(".step-btn."+elem.name).empty();
        $(".step-btn."+elem.name).append('<i class="fa fa-circle"></i>')
        //$(".step-container").scrollLeft($(".step-btn."+elem.name).position().left+50)
        //document.querySelector(".step-btn."+elem.name).scrollIntoView()

        
        if(!isPlaying){

            $(".step-info .step-"+step).addClass('highlight')
            //let st = document.querySelector(".step-info .step-"+step);
            //if(st) st.scrollIntoView();
        }
            
        
    }
    
    if(stepTracker[position] && step == stepTracker[position].max){
        clearInterval(timers[position]);
    }
}


const showOverlay = (position) => {

    let el = scene.getObjectByName(position+'_1');
    el.material.color = new THREE.Color('#00CC00');
    el.material.transparent = true;
    el.material.opacity = 0.5
    el.material.colorWrite = true;
    el.visible = true;
    el.material.needsUpdate = true;
}

const toggleGrid = (type) => {

    if(scene.getObjectByName('grid') == null){
        const size = 100;
        const divisions = 100;
    
        const gridHelper = new THREE.GridHelper( size, divisions );
        gridHelper.name = 'grid';
        gridHelper.material.depthTest = false;
        gridHelper.visible = false;
        scene.add( gridHelper );

        let v = gridHelper.clone();
        v.name = 'vgrid';
        v.rotateZ(THREE.MathUtils.degToRad(90));
        scene.add(v);

        let h = gridHelper.clone();
        h.name = 'hgrid';
        h.rotateZ(THREE.MathUtils.degToRad(90));
        h.rotation.y = THREE.MathUtils.degToRad(90)
        scene.add(h);  
    }

    
    scene.getObjectByName(type).visible = !scene.getObjectByName(type).visible;
    //scene.getObjectByName('vgrid').visible = gridVisible;
    //scene.getObjectByName('hgrid').visible = gridVisible;
    controlState.updateRender = true;

    toggleMobileMenu();
    
}

const toggleMobileMenu = () => {
    $(".navbar-collapse").removeClass('show');
}

const hideAll = (position) =>{
    //for(let key in positions){
        $(".step-info .step").removeClass('highlight')
        scene.getObjectByName(position).children.forEach((el) => {
            $(".step-btn."+el.name).empty();
            $(".step-btn."+el.name).append('<i class="fa-regular fa-circle"></i>')
            
            el.position.x = 0; //el.position_backup.x
            el.position.y = 0; //el.position_backup.y
            el.position.z = 0; //el.position_backup.z
            el.visible =  false;
            
                
        })
    //}
}

const arcate = (arc) => {

    for(let pos in positions){
        scene.getObjectByName(pos).visible = false;
    }

    for(let a of arc){

        scene.getObjectByName(a).visible = true;
    }

    controlState.updateRender = true;
    
}

const abilitaSovrapposizione = () =>{
    sovrapposizione = !sovrapposizione;
    $(".sovrapposizione").toggleClass('bold')
    $('.over-btn').toggleClass('active');

    showStep('superiore', stepTracker.superiore.step);
    showStep('inferiore', stepTracker.inferiore.step);

    toggleMobileMenu();
}

const toggleLeftPanel = (item) => {
    $(".left .inner-panel").hide();
    $(".left ."+item).show();

    if(leftPanelOpen && currentPanel != item){
        currentPanel = item;
        return;
    }
    
    if(leftPanelOpen){
        hideLeftPanel()
        currentPanel = '';
    }   
    else{
        currentPanel = item;
        showLeftPanel()
    }
        

    leftPanelOpen = !leftPanelOpen
    toggleMobileMenu();

}

const toggleRightPanel = () => {
    if(rightPanelOpen)
        hideRightPanel()
    else
        showRightPanel()

    rightPanelOpen = !rightPanelOpen
    toggleMobileMenu();

}

const showRightPanel = () => {
    containerPadding += 320;
    gsap.timeline()
    .to(".canvas-container", {duration: 0.1, alpha: 0})
    .to(".right", {right: '0px'})
    .set(".canvas-container", {flexBasis: 'calc(100% - '+containerPadding+'px)'})
    .then(() => { resizeCanvas() })
    .then(() => {gsap.to(".canvas-container", {alpha: 1})})
}

const hideRightPanel = () => {
    containerPadding -= 320;
    gsap.timeline()
    .to(".canvas-container", {duration: 0.1, alpha: 0})
    .to(".right", {right: '-320px'})
    .set(".canvas-container", {flexBasis: 'calc(100% - '+containerPadding+'px)'})
    .then(() => { resizeCanvas() })
    .then(() => {gsap.to(".canvas-container", {alpha: 1})})
}

const showLeftPanel = () => {
    containerPadding += 320;
    gsap.timeline()
    .to(".canvas-container", {duration: 0.1, alpha: 0})
    .to(".left", {left: '0px'})
    .set(".canvas-container", {flexBasis: 'calc(100% - '+containerPadding+'px)', marginLeft: '320px'})
    .then(() => { resizeCanvas() })
    .then(() => {gsap.to(".canvas-container", {alpha: 1})})
}

const hideLeftPanel = () => {
    containerPadding -= 320;

    gsap.timeline()
    .to(".canvas-container", {duration: 0.1, alpha: 0})
    .to(".left", {left: '-340px'})
    .set(".canvas-container", {flexBasis: 'calc(100% - '+containerPadding+'px)', marginLeft: '0px'})
    .then(() => { resizeCanvas() })
    .then(() => {gsap.to(".canvas-container", {alpha: 1})})
}

window.toggleFooter = () =>{
    $('footer').toggleClass('open');
    $(".show-steps").toggleClass('open');

        
    
}